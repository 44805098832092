import { createSelector } from 'reselect'
import _ from 'lodash'

import { getTripStatus } from '../utils/trips'

export const getTrips = (state) => {
  return _.get(state, 'trips.trips.results', []).map((trip) => {
    return { ...trip, formattedStatus: getTripStatus(trip) }
  })
}

export const getPastTrips = (state) => {
  return _.get(state, 'trips.pastTrips.results', []).map((trip) => {
    return { ...trip, formattedStatus: getTripStatus(trip) }
  })
}

export const getTripStatuses = (state) => {
  return _.get(state, 'trips.tripStatuses.results', []).map((tripStatus) => {
    return { ...tripStatus, formattedStatus: getTripStatus(tripStatus) }
  })
}

export const getDrivers = (state) => _.get(state, 'drivers.results', [])
export const getVehicles = (state) => _.get(state, 'vehicles.results', [])
export const getBulkDrivers = (state) => _.get(state, 'drivers.bulkCreate', [])
export const getBulkVehicles = (state) =>
  _.get(state, 'vehicles.bulkCreate', [])

export const getInitialDriver = createSelector(getDrivers, (drivers) =>
  _.get(drivers, '[0]', {})
)

export const getInitialVehicle = createSelector(getVehicles, (vehicles) =>
  _.get(vehicles, '[0]', {})
)

export const getInitialPreferredVehicle = createSelector(
  getVehicles,
  getInitialDriver,
  getInitialVehicle,
  (vehicles, driver, initialVehicle) => {
    const vehicle = _.find(
      vehicles,
      (v) => v.id === driver.preferred_vehicle_id
    )
    return vehicle || initialVehicle
  }
)

export const getNextTrips = (state) => _.get(state, 'trips.trips.next', null)
export const getNextPastTrips = (state) =>
  _.get(state, 'trips.pastTrips.next', null)

export const getProviderInformation = (state) => ({
  providerId: state.session.providerInfo?.id ?? '',
  providerName: state.session.providerInfo?.name ?? '',
})
