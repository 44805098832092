import { styled } from 'mz-ui-kit'
import { Link } from 'react-router-dom'

export const Container = styled('nav', ({ $palette }) => ({
  width: '220px',
  display: 'flex',
  padding: '20px 0',
  flexDirection: 'column',
  background: `${$palette('GRAY').lighten(5)}`,
}))

export const SubItemContainer = styled('nav', () => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const Item = styled(Link, ({ $active, $palette }) => ({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  padding: '10px 80px 10px 40px',
  margin: '10px 0',
  borderLeft: $active
    ? `6px solid ${$palette('PRIMARY')}`
    : '8px solid transparent',
  color: $active ? `${$palette('PRIMARY')}` : `${$palette('TEXT')}`,
}))

export const Badge = styled('span', () => ({
  backgroundColor: '#FCD012',
  padding: '4px 8px',
  fontSize: '14px',
  borderRadius: '4px',
  marginLeft: '10px',
}))
