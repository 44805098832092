import { createReducer } from 'app/utils/redux'
import { SET_AUTH_ERROR, SET_PROVIDER_INFO } from 'app/constants/actions'

const initialState = {
  redirect: false,
  error: null,
}

const actionHandlers = {
  [SET_AUTH_ERROR]: (state, action) => {
    return { ...state, error: action.payload }
  },
  [SET_PROVIDER_INFO]: (state, action) => {
    return { ...state, providerInfo: action.payload }
  },
}

export default createReducer(initialState, actionHandlers)
