import { styled } from 'mz-ui-kit'
import { BIG_SEPARATOR } from 'app/constants/sizes'

export const Tabs = styled('div', ({ $palette }) => ({
  display: 'flex',
  height: '46px',
  padding: `0 ${BIG_SEPARATOR}`,
  borderBottom: `1px solid ${$palette('GRAY')}`,
}))

export const Tab = styled('div', ({ $active, $palette }) => ({
  display: 'flex',
  cursor: 'pointer',
  padding: '10px 60px',
  marginRight: '40px',
  color: $active ? `${$palette('PRIMARY')}` : `${$palette('DARKGRAY')}`,
  borderBottom: $active ? `4px solid ${$palette('PRIMARY')}` : 'none',
}))

export const TabContent = styled('div', ({ $active }) => ({
  display: $active ? 'inherit' : 'none',
  padding: `${BIG_SEPARATOR} 0`,
}))

export const Status = styled('div', ({ $background, $loading, $palette }) => ({
  width: 'max-content',
  padding: '6px',
  textAlign: 'center',
  borderRadius: '3px',
  textTransform: 'capitalize',
  color: `${$palette($loading ? 'BLACK' : 'WHITE')}`,
  background: `${$palette($loading ? 'WHITE' : $background)}`,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export const StatusLoader = styled('div', ({ $background, $palette }) => ({
  width: 'max-content',
  height: '25.8px',
  textAlign: 'center',
  background: `${$palette($background)}`,
  alignItems: 'center',
}))

export const StatusDropdown = styled('div', () => ({
  position: 'absolute',
  top: '30px',
  left: '0px',
  width: '200px',
  backgroundColor: '#fff',
  borderRadius: '4px',
  boxShadow: '2px 2px 8px 0px rgba(34, 34, 34, .2)',
  padding: '10px',
  zIndex: 100,
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
}))

export const StatusDropdownItem = styled('div', ({ $active }) => ({
  fontSize: '16px',
  color: $active ? '#222' : '#666',
  fontWeight: $active ? 500 : 400,
  textAlign: 'left',
  transition: 'color .2s',
  textTransform: 'capitalize',

  ':hover': {
    color: '#222',
  },
}))

export const Alert = styled('section', ({ $palette }) => ({
  padding: '15px 20px',
  marginBottom: '20px',
  fontSize: '12px',
  color: `${$palette('RED')}`,
  background: `${$palette('RED').lighten(40)}`,
}))

export const Bolder = styled('span', {
  margin: '0 5px',
  fontWeight: 'bold',
})

export const LoadingWrapper = styled('div', {
  minWidth: '18px',
  paddingLeft: '10px',
})

export const ConfirmationNumber = styled('div', ({ $alert, $palette }) => ({
  color: `${$alert ? $palette('WHITE') : $palette('GREY')}`,
  fontSize: '11px',
  lineHeight: '19px',
}))

export const Link = styled('a', ({ $link }) => ({
  textDecoration: 'none',
  color: $link ? 'blue' : '#000000',
  display: 'flex',
  alignItems: 'center',
  padding: '8px 16px',
}))

export const VipBlock = styled('div', () => ({
  backgroundColor: '#FCD012',
  padding: '6px 20px',
  color: '#000',
  borderRadius: '40px',
  fontWeight: 'bold',
  display: 'inline-block',
  fontSize: '12px',
  textAlign: 'center',
  cursor: 'pointer',
  position: 'relative',
}))

// Styled Tooltip
export const Tooltip = styled('div', () => ({
  visibility: 'hidden',
  backgroundColor: '#333',
  color: '#fff',
  padding: '10px 15px',
  borderRadius: '10px',
  position: 'absolute',
  top: '-140px',
  left: '50%',
  transform: 'translateX(-50%)',
  width: '250px',
  textAlign: 'left',
  zIndex: 10,
  fontSize: '14px',
  lineHeight: '1.5',
  opacity: 0,
  transition: 'opacity 0.3s ease',
  '::after': {
    content: '""',
    position: 'absolute',
    top: '100%',
    left: '50%',
    marginLeft: '-10px',
    borderWidth: '10px',
    borderStyle: 'solid',
    borderColor: '#333 transparent transparent transparent',
  },
}))

export const TooltipTitle = styled('div', () => ({
  fontWeight: 'bold',
  marginBottom: '5px',
}))

export const VipBlockContainer = styled('div', () => ({
  position: 'relative',
  display: 'inline-block',
  ':hover div': {
    visibility: 'visible',
    opacity: 1,
  },
}))

export const InfoAlert = styled('div', () => ({
  backgroundColor: `#DFD7FA`,
  padding: '20px',
  marginBottom: '16px',
  display: 'flex',
  alignItems: 'flex-start',
  borderRadius: '4px',
  fontFamily: 'Manrope, sans-serif',
}))

export const LearnMoreBtn = styled('a', {
  textDecoration: 'none',
  backgroundColor: '#0B77D5',
  color: '#fff',
  display: 'inline-flex',
  alignItems: 'center',
  padding: '4px 8px',
  fontSize: '14px',
  borderRadius: '4px',
  cursor: 'pointer',
  flex: 1,
  ':hover': {
    backgroundColor: '#1266CC',
  },
})

export const AlertTitle = styled('p', () => ({
  fontSize: '22px',
  fontWeight: 'bold',
  margin: 0,
}))

export const AlertDescription = styled('p', () => ({
  fontSize: '16px',
  marginTop: '6px',
}))

export const RemoveIcon = styled('img', {
  marginLeft: 'auto',
  cursor: 'pointer',
})

export const EarnIcon = styled('img', {
  marginRight: '20px',
})
