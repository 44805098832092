import { styled } from 'mz-ui-kit'

export const Container = styled('div', ({ $palette }) => ({
  width: '400px',
  maxWidth: '100%',
  padding: '20px',
  color: `${$palette('TEXT')}`,
}))

export const Heading = styled('h2', ({ $palette }) => ({
  color: `${$palette('PRIMARY')}`,
}))

export const Section = styled('div', {
  marginBottom: '40px',
})

export const Bolder = styled('span', {
  fontWeight: 'bold',
  marginRight: '4px',
})

export const RouteLink = styled('p', {
  textDecoration: 'underline',
})

export const Buttons = styled('div', {
  display: 'flex',
  flexFlow: 'column nowrap',
  alignItems: 'flex-end',
  padding: '20px 0',
})

export const Spacer = styled('div', ({ $horizontal }) => ({
  width: $horizontal ? '5px' : '100%',
  height: $horizontal ? '100%' : '10px',
}))

export const Verified = styled('div', ({ $palette }) => ({
  display: 'flex',
  fontWeight: 'bold',
  color: `${$palette('PRIMARY')}`,
}))

export const CustomLabelTag = styled('span', ({ $palette }) => ({
  color: `${$palette('PRIMARY')}`,
  display: 'inline-block',
  marginTop: '16px',
  fontSize: '12px',
}))

export const ConfirmationNumber = styled('span', ({ $palette }) => ({
  color: `${$palette('GREY')}`,
  fontSize: '13px',
  marginTop: '8px',
  textDecoration: 'underline',
  marginBottom: '16px',
  cursor: 'pointer',
  display: 'block',
}))

export const MinFrame = styled('div', {
  minHeight: '440px',
  minWidth: '440px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export const ErrorMessage = styled('div', ({ $palette }) => ({
  color: `${$palette('RED')}`,
}))

export const ErrorMessageWrapper = styled('div', ({ $palette }) => ({
  color: `${$palette('RED')}`,
  padding: '20px 0 0',
}))

export const AmenitiesList = styled('ol', () => ({
  paddingLeft: '0',
  paddingBottom: '0',
  marginBottom: '0',
}))

export const AmenitiesListItem = styled('li', () => ({
  display: 'flex',
  alignItems: 'center',
  padding: '8px 0',
}))

export const AmenityImage = styled('img', () => ({
  width: '20px',
  height: '20px',
  marginRight: '10px',
}))
