import { styled } from 'mz-ui-kit'

export const Button = styled('button', ({ $palette }) => ({
  padding: '8px 16px',
  border: '0',
  background: 'none',
  fontSize: '16px',
  cursor: 'pointer',
  marginLeft: 'auto',
  marginRight: '16px',
  color: `${$palette('PRIMARY')}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: '300',
}))

export const HelpIcon = styled('img', () => ({
  marginRight: '4px',
}))
