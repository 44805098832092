import React from 'react'
import { Container, Branding, Logo, Title, Beta } from './elements'
import LogoutButton from 'app/components/commons/LogoutButton'
import HelpCenterButton from 'app/components/commons/HelpCenterButton'
import logo from 'img/logo.svg'

const Navbar = () => (
  <Container>
    <Branding>
      <Logo src={logo} alt="logo" />
      <Title>Provider Dashboard</Title>
      <Beta>BETA</Beta>
    </Branding>
    <HelpCenterButton />
    <LogoutButton />
  </Container>
)

export default Navbar
