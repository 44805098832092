import { useState, useEffect } from 'react'
import posthog from 'posthog-js'

export const usePriceUpdatePageAccessFeatureFlag = (
  providerName,
  providerId
) => {
  const [isEnabled, setIsEnabled] = useState(false)

  useEffect(() => {
    posthog.setPersonPropertiesForFlags({
      provider_name: providerName,
      provider_id: providerId,
    })

    const checkFeatureFlag = async () => {
      const enabled = await posthog.isFeatureEnabled('price_update_page_access')
      setIsEnabled(enabled)
    }

    posthog.onFeatureFlags(checkFeatureFlag)
  }, [providerName])

  return isEnabled
}
