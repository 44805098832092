import React, { useState } from 'react'
import posthog from 'posthog-js'
import {
  InfoAlert,
  LearnMoreBtn,
  AlertTitle,
  AlertDescription,
  RemoveIcon,
  EarnIcon,
} from './elements'

import earn from 'img/earn.svg'
import x from 'img/x.svg'
import rightArrow from 'img/icon-right.svg'

const PAYMENT_ELIGIBILITY_BANNER_DISMISSED =
  'payment_eligibility_banner_dismissed'

const LEARN_MORE_URL =
  'https://mozio.notion.site/Mozio-Bi-Weekly-Payment-Criteria-1a85cb3b82278007b9b2f48682c4b13a?pvs=4'

export const FeatureFlagAlert = () => {
  const shouldShowBanner = !localStorage.getItem(
    PAYMENT_ELIGIBILITY_BANNER_DISMISSED
  )
  const [showBanner, setShowBanner] = useState(shouldShowBanner)

  if (!posthog.isFeatureEnabled('payment_eligibility_banner')) {
    return null
  }

  if (!showBanner) {
    return null
  }

  const onDismissBanner = () => {
    localStorage.setItem(PAYMENT_ELIGIBILITY_BANNER_DISMISSED, '1')
    setShowBanner(false)
  }

  const captureLearnMoreClick = () => {
    posthog.capture('payment_eligibility_banner_learn_more_clicked')
  }

  return (
    <InfoAlert>
      <EarnIcon src={earn} alt="earn" />
      <div>
        <AlertTitle>Get Paid Faster!</AlertTitle>
        <AlertDescription>
          Providers who maintain excellent quality and track compliance using
          the Mozio Driver App can now switch from monthly to bi-weekly
          payments.
        </AlertDescription>
        <LearnMoreBtn href={LEARN_MORE_URL} onClick={captureLearnMoreClick}>
          Learn more: Eligibility Criteria{' '}
          <img src={rightArrow} alt="arrow-right" />
        </LearnMoreBtn>
      </div>
      <RemoveIcon src={x} alt="x" onClick={onDismissBanner} />
    </InfoAlert>
  )
}
