export const REFRESH_TOKEN_ENDPOINT = '/auth/token-refresh/'
export const LOGIN_ENDPOINT = '/auth/drivers/token'
export const PROVIDER_UID_ENDPOINT = '/data-entry/providers/by-driver-user/'
export const TRIPS_ENDPOINT = '/data-entry/vehicle-tracking/reservations/'
export const FUTURE_TRIPS_ENDPOINT =
  '/data-entry/vehicle-tracking/reservations/future/'
export const PAST_TRIPS_ENDPOINT =
  '/data-entry/vehicle-tracking/reservations/past/'
export const VEHICLES_ENDPOINT = '/data-entry/vehicle-tracking/vehicles/'
export const DRIVERS_ENDPOINT = '/auth/drivers/'
export const CITIES_ENDPOINT =
  '/data-entry/vehicle-tracking/reservations/available_cities/'
export const TRIP_ENDPOINT = '/data-entry/vehicle-tracking/reservations/'
