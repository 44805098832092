import React from 'react'
import { Button, HelpIcon } from './elements'
import InfoIcon from 'img/info.svg'

const REDIRECT_URL = 'https://moziosupply.zendesk.com/hc/en-us'

const HelpCenterButton = () => {
  const redirectUrl = () => {
    window.location.href = `${REDIRECT_URL}?fromPath=${window.location.href}`
  }
  return (
    <Button onClick={redirectUrl}>
      <HelpIcon src={InfoIcon} alt="info-icon" /> Help
    </Button>
  )
}

export default HelpCenterButton
