import { createAction } from 'app/utils/redux'
import Client, { saveJWTToken } from 'mz-sdk/client'
import { LOGIN_ENDPOINT, PROVIDER_UID_ENDPOINT } from 'app/constants/endpoints'
import {
  LOGIN_USER,
  SET_AUTH_ERROR,
  SET_PROVIDER_INFO,
} from 'app/constants/actions'
import posthog from 'posthog-js'

export const login = createAction(LOGIN_USER)

export const setAuthError = createAction(SET_AUTH_ERROR)

export const setProviderInfo = createAction(SET_PROVIDER_INFO)

export const doLogin = (userData) => {
  return async (dispatch) => {
    try {
      const authResponse = await Client.post(LOGIN_ENDPOINT, { body: userData })
      posthog.identify(userData.email, {
        user_email: userData.email,
      })
      return saveJWTToken(authResponse)
        ? dispatch(setAuthError(null))
        : dispatch(setAuthError('Unable to authenticate'))
    } catch (error) {
      // TODO: use error message from the backend when we will decide
      //       how to handle error messages in general
      posthog.capture('Provider Dashboard Sign-in Error', {
        provider_email: userData.email,
        error_code: error.response?.code,
        error_message: error.response?.message,
      })
      return dispatch(
        setAuthError('Unable to login with given email and password.')
      )
    }
  }
}

export const getProviderInfo = () => {
  return async (dispatch) => {
    try {
      const providerData = await Client.get(PROVIDER_UID_ENDPOINT)
      dispatch(setProviderInfo(providerData))
      return providerData
    } catch (error) {
      posthog.capture('Provider Info Fetch Error', {
        error_code: error.response?.code,
        error_message: error.response?.message,
      })
      return null
    }
  }
}
